.Overlay {
    .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}