.MuiFormLabel-root.Mui-focused {
    color: #00C2D4;
}

.MuiPickersDay-daySelected {
    background-color: #00C2D4 !important;
}

.MuiPickersDay-current {
    color: #E2E2E2 !important;
}

.Mui-focused {
    
    .MuiOutlinedInput-notchedOutline {
        border-color: #00C2D4 !important;
    }
    
}

.Mui-focused {
    border-color: #00C2D4 !important;
}

.MuiInput-underline:after {
    border-color: #00C2D4 !important;
}